import Layout from '@/components/core/Layout';
import { useLottiePlayer } from '@starsoft/common/hooks';
import { Button } from '@starsoft/common/components';
import useTranslation from 'next-translate/useTranslation';
import styles from '@/styles/pages/NotFound/styles.module.scss';
import { useRouter } from 'next/router';

export default function NotFoundPage() {
  const { lottieAnimationRef } = useLottiePlayer({
    path: '/404.json',
  });
  const { t } = useTranslation('common');
  const { push } = useRouter();

  function handleRoute() {
    push('/');
  }

  return (
    <Layout>
      <div className={styles.lottie} ref={lottieAnimationRef} />
      <div className={styles.row}>
        <span className={styles.code}>{t('not_found_code')}</span>
        <span className={styles.divider}>|</span>
        <p className={styles.text}>{t('not_found_text')}</p>
      </div>
      <Button onClick={handleRoute} className={styles.button}>
        {t('not_found_button')}
      </Button>
    </Layout>
  );
}
